<template>
  <div class="option-field">
    <div class="option-loader" v-if="loading">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <base-input
      v-model="optionModel"
      v-if="option.value_type == OPTION_TYPE_NUMBER"
      type="number"
      step="0.001"
    ></base-input>

    <base-input v-if="option.value_type == OPTION_TYPE_CHECKBOX">
      <base-checkbox v-model="optionModel"> </base-checkbox>
    </base-input>

    <base-input v-if="option.value_type == OPTION_TYPE_RADIO">
      <base-radio
        v-model="optionModel"
        v-for="opt in option.data"
        :key="opt"
        :value="opt"
        :name="opt"
      >
        {{ $te(`OPTIONS.${opt}`) ? $t(`OPTIONS.${opt}`) : opt }}
      </base-radio>
    </base-input>

    <base-input
      v-model="optionModel"
      v-if="option.value_type == OPTION_TYPE_STRING"
      type="string"
    ></base-input>

    <base-input v-if="option.value_type == OPTION_TYPE_WYSIWYG">
      <html-editor v-model="optionModel"> </html-editor>
    </base-input>

    <base-input v-if="option.value_type == OPTION_TYPE_OBJECT">
      <object-selector
        :object="option.value?.toString()"
        :objectType="option.data.ressource"
        :objectSearchField="option.data.searchField"
        :filterOrganization="option.organization.id"
        @objectChanged="
          (objectId) => {
            optionModel = objectId;
          }
        "
      />
    </base-input>

    <base-input v-if="option.value_type == OPTION_TYPE_IMAGE">
      <image-selector
        :defaultImage="optionModel"
        ressource_name="options"
        :ressource_id="option.id ?? 0"
        field="logo"
        @imageChanged="
          (file_url) => {
            optionModel = file_url;
          }
        "
        inline
      />
    </base-input>
  </div>
</template>

<script>
import { cloneDeep, debounce } from "lodash";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import {
  OPTION_TYPE_NUMBER,
  OPTION_TYPE_STRING,
  OPTION_TYPE_WYSIWYG,
  OPTION_TYPE_IMAGE,
  OPTION_TYPE_OBJECT,
  OPTION_TYPE_ARRAY,
  OPTION_TYPE_CHECKBOX,
  OPTION_TYPE_RADIO,
} from "@/constants/options";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import ObjectSelector from "@/components/ObjectSelector.vue";
// import ObjectsSelector from "@/components/ObjectsSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
    BaseCheckbox,
    ObjectSelector,
    // ObjectsSelector,
    ImageSelector,
  },

  mixins: [],

  props: ["option"],

  data() {
    return {
      loading: false,
      optionModel: cloneDeep(this.option.value),
      OPTION_TYPE_NUMBER,
      OPTION_TYPE_STRING,
      OPTION_TYPE_WYSIWYG,
      OPTION_TYPE_IMAGE,
      OPTION_TYPE_OBJECT,
      OPTION_TYPE_ARRAY,
      OPTION_TYPE_CHECKBOX,
      OPTION_TYPE_RADIO,
    };
  },

  created() {},

  methods: {
    debouncedUpdate: debounce(function () {
      this.updateOption();
    }, 300),

    async updateOption() {
      this.loading = true;
      const optionData = cloneDeep(this.option);
      optionData.value = this.optionModel;
      try {
        await this.$store.dispatch("options/update", optionData);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  watch: {
    optionModel: {
      handler: "debouncedUpdate",
      deep: true,
    },
  },
};
</script>

<style>
.option-field {
  position: relative;
}
.option-loader {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
</style>
